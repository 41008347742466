<template>
  <v-dialog
    v-model="showDialog"
    class="custom-dialog"
    max-width="550px"
  >
    <v-container
      style="background-color: #F6F1EE; color: black; border-radius: 20px; scrollbar-width: none; padding: 0 !important; overflow-y: scroll;"
    >
      <h3
        class="text-center py-3"
        style="background-color: black; color: white;"
      >
        <v-icon class="mr-1">
          mdi-clock
        </v-icon>
        {{ 'Limited time offer, claim it now' }}
      </h3>
      <v-container
        :key="option.title"
        class="px-8"
      >
        <h2 class="tw-font-bold tw-text-xl">
          {{ option.title }}
        </h2>
        <p class="d-flex flex-row align-center py-3">
          <span class="mt-1 mr-1">5/5</span>
          <v-icon
            v-for="index in 5"
            :key="index"
            style="color: orange;"
          >
            mdi-star
          </v-icon>
          <span class="mt-1 ml-1">Rated by verified customers</span>
        </p>
        <div class="d-flex flex-row align-center">
          <h3
            class="mr-2"
            style="color: #949494; text-decoration: line-through;"
          >
            {{ option.priceBefore }}
          </h3>
          <h2>{{ option.price }}</h2>
          <h3
            class="ml-2"
            style="color: #B1D37F"
          >
            (save {{ option.discount }})
          </h3>
        </div>
      </v-container>
      <v-divider class="my-2" />
      <v-container class="px-8">
        <div
          :key="option.title"
        >
          <div class="d-flex flex-row justify-space-between align-center">
            <h3 class="tw-font-bold tw-text-lg">
              <v-icon class="mb-1">
                mdi-account
              </v-icon>
              {{ option.title }}
            </h3>
            <h3 class="tw-font-bold tw-text-lg">
              {{ option.pricePeriod }}
            </h3>
          </div>
          <ul>
            <li
              v-for="feature in option.features"
              :key="feature"
              style="font-size: medium;"
            >
              {{ feature }}
            </li>
          </ul>
        </div>
        <div
          class="text-center mt-5 d-flex flex-column align-center"
          style="font-size: small !important;"
        >
          <p
            class="d-flex flex-row align-center my-2"
            style="font-size: large; color: #949494;"
          >
            <v-icon>mdi-lock</v-icon>
            <span
              class="ml-1"
              style="font-size: small !important;"
            >30 day money-back guarantee</span>
          </p>
          <v-btn
            class="my-5 button"
            color="white"
            @click="openNextDialog"
          >
            {{ 'Claim discount' }}
          </v-btn>
          <v-card-actions>
            <h4
              style="cursor: pointer; text-decoration: underline; color: #949494;"
              @click="closeAllDialogs"
            >
              {{ 'No thanks, I\'ll pay full price later' }}
            </h4>
          </v-card-actions>
        </div>
      </v-container>
    </v-container>
  </v-dialog>
  <v-dialog
    v-model="showConfirmDiscount"
    class="custom-dialog"
    max-width="550px"
    persistent
  >
    <UpgradeToPaid
      :show-confirm-discount="showConfirmDiscount"
      @close-dialog="closeAllDialogs"
    />
  </v-dialog>
</template>

<script setup lang="ts">
import { ref, onMounted, Ref, inject, computed } from 'vue'
import { useUserStore } from '@/store/user'
import { getOption } from '@/services/discountOptions'
import { isUserLoggedIn } from '@/auth'
import UpgradeToPaid from '@/components/UpgradeToPaid.vue'
import tracking from '@/services/tracking'

const userStore = useUserStore()
const showDialog = inject<Ref<boolean>>('activateNowModal')
const showConfirmDiscount = ref(false)

const option = computed(() => {
  return getOption(userStore.org?.tier!)!
})

const openNextDialog = () => {
  tracking.track('Activate Now Dialog Claim Discount Clicked')
  showDialog!.value = false
  showConfirmDiscount.value = true
}

const closeAllDialogs = () => {
  showDialog!.value = false
  showConfirmDiscount.value = false
}

onMounted(async () => {
  const loggedIn = await isUserLoggedIn()

  if (!loggedIn) {
    return
  }

  tracking.track('Locked Export Upgrade Modal Shown')

  try {
    await userStore.setup()
    window.addEventListener('close-all-dialogs', closeAllDialogs)
  } catch (error) {
    console.error('Error setting up user store:', error)
  }
})

</script>

<style lang="scss" scoped>
.banner-container {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 39;
  background-color: #FF9ECF;
  color: black;
}

.custom-dialog {
  backdrop-filter: blur(10px);
}

.custom-dialog .v-card {
  background-color: #F6F1EE !important;
}

ul {
  list-style: none;
  padding: 15px 0 0;

    li {
      position: relative;
      padding-left: 25px;
      margin-bottom: 5px;
      font-size: large;
      color: #949494;
      text-decoration: underline dotted;

        &::before {
          content: "✔";
          text-decoration: none;
          position: absolute;
          left: 0;
        }
    }
}

.button {
  border: 2px solid #FF9DCF;
  font-weight: bolder;
  width: 80%;
  height: 50px !important;
  border-radius: 20px;
  background-color: white;
}
</style>
