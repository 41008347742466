import { defineStore, acceptHMRUpdate } from 'pinia'
import { commitProject, cleanProject, getCurrentTimestamp, getProjects as getProjectsFromDb, deleteProject as deleteProjectFromDb, subscribeToProjectChanges, dbUpdateTicker } from '@/services/databaseHelper'
import { computed, ref, watch } from 'vue'
import { useRoute } from 'vue-router'
import { getAxios } from '@/services/axiosHelper'
import { Project, ProjectSettings, CurrentProject, Character, VoiceoverVoice } from '@/types'
import hash from 'object-hash'
import { RealtimeChannel } from '@supabase/supabase-js'
import { useFrameStateStore } from '@/store/frameState'

export const useAppStore = defineStore('app', () => {
  const projects = ref<Project[] | null>(null)
  const currentProjectId = ref<string | null>(null)
  const promisedTimeout = ref<null | NodeJS.Timeout>(null)
  const voiceoverAllVoices = ref<VoiceoverVoice[] | null>(null)
  const frameStateStore = useFrameStateStore()

  const getVoiceoverVoices = async (): Promise<void> => {
    const axios = await getAxios()
    const response = await axios.get('/api/tts/get-voices')
    voiceoverAllVoices.value = response.data
  }

  const clearDraftProject = {
    id: 'draft',
    projectSettings: {
      style: 'cinematic',
      aspectRatio: 'landscape'
    },
    lastUpdated: '',
    created: '',
    deleted: false,
    name: 'New Project',
    frames: [],
    custom_appearances: [],
    scenes: [],
    characters: []
  }

  const draftProject = ref(JSON.parse(JSON.stringify(clearDraftProject)) as CurrentProject)

  const route = useRoute()

  let interval: null | NodeJS.Timeout = null
  let channel: null | RealtimeChannel = null

  watch(() => route, async () => {
    if (route.name === 'Home') {
      if (interval) {
        clearInterval(interval)
      }
      if (channel) {
        channel.unsubscribe()
      }
    }
  }, { deep: true })

  const setCurrentProjectId = (newProjectId: string) => {
    console.debug('setCurrentProjectId', newProjectId)
    currentProjectId.value = newProjectId

    interval = publishUpdates()

    channel = subscribeToProjectChanges(newProjectId, projects)
  }

  const clearDraft = () => {
    draftProject.value = JSON.parse(JSON.stringify(clearDraftProject)) as CurrentProject
  }

  const currentProject = computed(() => {
    if (currentProjectId.value === 'draft') {
      return draftProject.value
    }
    const project = projects.value?.find((p) => p.id === currentProjectId.value)

    if (!project) {
      throw new Error('Project not found')
    }

    if (!project.characters) {
      project.characters = []
    }

    if (!project.scenes) {
      project.scenes = []
    }
    return project as CurrentProject
  })

  const maybeCurrentProject = computed(() => {
    return projects.value?.find((p) => p.id === currentProjectId.value)
  })

  const publishUpdates = () => {
    let lastProjectState: { hash: string, time: number } | null = null

    return setInterval(async () => {
      if (!maybeCurrentProject.value) {
        return
      }

      const currentProjectState = {
        hash: hash(cleanProject(maybeCurrentProject.value)),
        time: new Date(maybeCurrentProject.value.lastUpdated).getTime()
      }

      if (!lastProjectState) {
        lastProjectState = { ...currentProjectState }
        return
      }

      if (currentProjectState.time > lastProjectState.time) {
        lastProjectState = { ...currentProjectState }
        console.debug('publish: skip, received server update')
        return
      }

      if (lastProjectState.hash === currentProjectState.hash) {
        console.debug('publish: skip, no change')
        dbUpdateTicker.emit('dbUpdate', maybeCurrentProject.value!.id)
        return
      }

      console.debug('publish: commiting update to firestore')
      const currentTimestamp = getCurrentTimestamp()

      lastProjectState = { hash: currentProjectState.hash, time: currentTimestamp.getTime() }

      maybeCurrentProject.value.lastUpdated = currentTimestamp.toISOString()
      commitProject(maybeCurrentProject.value, currentTimestamp).then(() => {
        dbUpdateTicker.emit('dbUpdate', maybeCurrentProject.value!.id)
      })
    }, 2000)
  }

  const getProjects = async (godProjectId: string | null = null): Promise<void> => {
    const projectsFromFirestore = await getProjectsFromDb(godProjectId)

    projectsFromFirestore.forEach(frameStateStore.initializeProjectState)

    projects.value = [...projectsFromFirestore]
  }

  const createProject = async ({ name, projectSettings, characters, moodboardId }: { name: string, projectSettings: ProjectSettings, characters?: Character[], moodboardId?: string }): Promise<Project> => {
    if (projects.value === null) {
      throw new Error('Projects not loaded')
    }

    const axios = await getAxios()

    const response = await axios.post('/api/create-project', {
      name,
      projectSettings,
      characters,
      moodboardId
    })

    await getProjects()

    const newProject = projects.value.find((p) => p.id === response.data.id)

    if (!newProject) {
      throw new Error('New project not found')
    }

    return newProject
  }

  const deleteProject = async (projectId: string): Promise<void> => {
    await deleteProjectFromDb(projectId)

    await getProjects()
  }

  const copyProject = async (projectId: string): Promise<void> => {
    const axios = await getAxios()

    await axios.post('/api/copy-project', { projectId })

    await getProjects()
  }

  const getCurrentProjectShareableUrl = async (): Promise<string> => {
    if (!currentProjectId.value) {
      throw new Error('No current project')
    }
    const axios = await getAxios()

    const response = await axios.post(`/api/project-token?projectId=${currentProjectId.value}`)
    const token = response.data
    return `/link/${currentProjectId.value}/${token}`
  }

  const getShareableProjectLink = async (projectId: string) => {
    const axios = await getAxios()

    const response = await axios.post(`/api/project-token?projectId=${projectId}`)
    const token = response.data
    return `/link/${projectId}/${token}`
  }

  const createMoodboard = async (name: string, images: File[], baseStyle: string): Promise<string> => {
    const axios = await getAxios()

    const formData = new FormData()
    images.forEach((image) => {
      formData.append('files', image)
    })

    formData.append('name', name)
    formData.append('baseStyle', baseStyle)

    const response = await axios.post('/api/create-moodboard', formData)

    return response.data.id
  }

  return {
    projects,
    currentProjectId,
    promisedTimeout,
    voiceoverAllVoices,
    setCurrentProjectId,
    getProjects,
    createProject,
    deleteProject,
    copyProject,
    currentProject,
    maybeCurrentProject,
    getCurrentProjectShareableUrl,
    getShareableProjectLink,
    clearDraft,
    createMoodboard,
    getVoiceoverVoices
  }
})

// eslint-disable-next-line
if ((import.meta as any).hot) {
  // eslint-disable-next-line
  (import.meta as any).hot.accept(acceptHMRUpdate(useAppStore, (import.meta as any).hot)) // ts-ignore
}
