const earlyActivationOptions = [
  {
    visibleForTiers: ['Essential'],
    title: 'Essential plan',
    price: '$19',
    priceBefore: '$39.00',
    pricePeriod: '3 months',
    discount: '50%',
    features: [
      '500 Image Credits',
      '100 Video Credits',
      'All creative features',
      '2 users',
      '200 Video Credits',
      '20 Project Limit',
      '1 Custom Character',
      '20 Panels per Project',
      'ZIP export'
    ],
    yearly: {
      priceBefore: '$348.00',
      price: '$174',
      pricePeriod: 'Billed Annually'
    }
  },
  {
    visibleForTiers: ['Pro'],
    title: 'Pro plan',
    price: '$35',
    discount: '10%',
    priceBefore: '$39.00',
    pricePeriod: '3 months',
    features: [
      '1000 Image Credits',
      '200 Video Credits',
      'Unlimited projects',
      '5 Custom Characters',
      '100+ panels per project',
      'ZIP export',
      'PPT, Video Export'
    ],
    yearly: {
      priceBefore: '$374.00',
      price: '$336.00',
      pricePeriod: 'Billed Annually'
    },
    blackFriday: {
      price: '$31.00',
      priceBefore: '$39.00',
      discount: '20%',
      yearly: {
        priceBefore: '$374',
        price: '$149',
        discount: '60%',
        pricePeriod: 'Billed Annually'
      }
    }
  },
  {
    visibleForTiers: ['Individual'],
    title: 'Unlimited plan',
    price: '$89',
    discount: '10%',
    priceBefore: '$99.00',
    pricePeriod: '3 months for 2 users',
    features: [
      'Unlimited image generation',
      'All creative features',
      '2 users',
      'ZIP export',
      'Unlimited Video Credits',
      'Unlimited projects',
      '10 Custom Characters',
      '200+ panels per project',
      'PPT, Video Export'
    ],
    yearly: {
      priceBefore: '$948.00',
      price: '$853',
      pricePeriod: 'Billed Annually'
    },
    blackFriday: {
      discountPrice: '$79.00',
      price: '$99.00',
      discount: '20%',
      yearly: {
        priceBefore: '$948',
        price: '$379',
        discount: '60%',
        pricePeriod: 'Billed Annually'
      }
    }
  },
  {
    visibleForTiers: ['Business'],
    title: 'Business plan',
    price: '$449',
    discount: '10%',
    priceBefore: '$499.00',
    pricePeriod: '3 months for 5 users',
    features: [
      'Unlimited image generation',
      'All creative features',
      'Unlimited image credits',
      'Unlimited video credits',
      'Unlimited projects',
      '25 custom characters',
      '200+ panels per project',
      'ZIP export',
      'PPT, Video export',
      '5 users',
      'Real-time collaboration'
    ],
    yearly: {
      priceBefore: '$4788.00',
      price: '$4309',
      pricePeriod: 'Billed Annually'
    }
  }
]

export const getOption = (userTier: string) => {
  return earlyActivationOptions.find(option => option.visibleForTiers.includes(userTier))
}
