<template>
  <v-card style="color: black; border-radius: 20px; scrollbar-width: none;">
    <v-progress-circular
      v-if="loading"
      class="mx-auto my-8"
      indeterminate
      color="primary"
      x-large
    />

    <v-container
      v-else-if="thankYou || paymentRejected"
      class="d-flex flex-column align-center"
      style="padding: 0 !important;"
    >
      <h3
        class="py-3 w-100 text-center"
        style="background-color: black; color: white;"
      >
        {{ thankYou ? 'Thank you for your purchase' : 'Payment Failed' }}
      </h3>
      <p
        class="my-3"
        :style="{ color: paymentRejected ? 'red' : 'inherit' }"
      >
        {{ thankYou ? 'Your order has been successfully processed.' : 'We\'re sorry, but your payment failed.' }}
      </p>
      <template v-if="paymentRejected">
        <h3
          :key="option.title"
          class="my-2 px-8 text-center"
        >
          Unfortunately, your payment for the {{ option.title }} at a discounted rate of {{ option.price }} was unsuccessful.
        </h3>
        <h3 class="my-3">
          Please update your payment details.
        </h3>
        <v-btn
          class="my-2 button pink-border"
          :loading="updatePaymentLoading"
          @click="redirectToCustomerPortal"
        >
          Update Payment Method
        </v-btn>
        <v-btn
          class="mt-2 mb-5 button"
          style="background-color: gainsboro;"
          @click="emits('close-dialog')"
        >
          Cancel
        </v-btn>
      </template>
      <template v-else>
        <h3 class="my-2">
          You can now enjoy all the premium features
        </h3>
        <v-btn
          class="my-5 button pink-border"
          @click="closeDialog"
        >
          Close
        </v-btn>
      </template>
    </v-container>

    <v-container
      v-else-if="showConfirmDiscount"
      class="text-center"
      style="padding: 0 !important;"
    >
      <v-card style="color: black; border-radius: 20px; scrollbar-width: none; background-color: #F6F1EE">
        <h3
          class="py-3 tw-font-bold tw-text-lg"
          style="background-color: black; color: white;"
        >
          Confirm your purchase
        </h3>
        <v-card-text>
          {{ `You're about to claim the ${option.title} at a discounted rate, saving ${option.discount}!` }}
          <h3 class="my-2 tw-font-bold tw-text-lg">
            You will be charged {{ option.price }} now
          </h3>
          <h3 class="my-2 tw-font-bold tw-text-lg">
            Are you sure you want to proceed?
          </h3>
          <p
            class="d-flex flex-row align-center mt-5 justify-center"
            style="font-size: large; color: #949494;"
          >
            <v-icon>mdi-lock</v-icon>
            <span
              class="ml-1"
              style="font-size: small !important;"
            >30 day money-back guarantee</span>
          </p>
        </v-card-text>
        <div
          class="d-flex flex-column align-center py-3"
          style="background-color: #F6F1EE !important;"
        >
          <v-btn
            class="my-2 button pink-border"
            @click="confirmUpgrade"
          >
            {{ `Confirm & save ${option.discount}` }}
          </v-btn>
          <v-btn
            class="my-2 button"
            style="background-color: gainsboro;"
            @click="emits('close-dialog')"
          >
            Cancel
          </v-btn>
        </div>
      </v-card>
    </v-container>
  </v-card>
</template>

<script setup lang="ts">
import { ref, defineProps, defineEmits, computed } from 'vue'
import { useUserStore } from '@/store/user'
import { getAxios } from '@/services/axiosHelper'
import tracking from '@/services/tracking'
import { getOption } from '@/services/discountOptions'

const emits = defineEmits(['close-dialog'])
defineProps({
  showConfirmDiscount: Boolean
})

const userStore = useUserStore()

const loading = ref(false)
const thankYou = ref(false)
const paymentRejected = ref(false)

const option = computed(() => {
  const option = getOption(userStore.org?.tier!)!

  return option
})

const confirmUpgrade = async () => {
  tracking.track('Confirm Upgrade Clicked')
  loading.value = true
  const axios = await getAxios()
  try {
    const response = await axios.post('/api/upgrade-to-paid')
    // wait for organization to be updated
    await new Promise(resolve => setTimeout(resolve, 5000))
    loading.value = false
    if (response && response.data.success === true) {
      thankYou.value = true
    } else {
      paymentRejected.value = true
    }
  } catch (error) {
    console.error('Payment failed:', error)
    loading.value = false
    paymentRejected.value = true
  }
}

const closeDialog = async () => {
  thankYou.value = false
  paymentRejected.value = false
  emits('close-dialog')
}

const updatePaymentLoading = ref(false)

async function redirectToCustomerPortal () {
  updatePaymentLoading.value = true
  tracking.track('Update Payment Method Clicked')
  const axios = await getAxios()
  const result = await axios.post('/api/update-payment-method')
  window.location.assign(result.data.redirect_url)
}

</script>

<style lang="scss" scoped>
.button {
  font-weight: bolder;
  width: 80%;
  height: 50px !important;
  border-radius: 20px;
  background-color: white;
}

.pink-border {
  border: 2px solid #FF9DCF;
}
</style>
