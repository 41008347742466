import { defineStore } from 'pinia'
import { Frame, PredictionPayload, VideoPayload, Project, GenerationProgress } from '@/types'
import { computed, ComputedRef, ref, reactive } from 'vue'

export interface FrameState {
  generationProgress: GenerationProgress
  videoInProgress: boolean
  imageInProgress: boolean
  inpaintInProgress: boolean
  styleTransferInProgress: boolean
  loadingIndicators: number
  editorImage: string | null
  editorVideo: string | null
  openAIPrompt: PredictionPayload | null
  videoPayload: VideoPayload | null
}

interface FrameStateMap {
  [frameId: string]: FrameState
}

export const useFrameStateStore = defineStore('frameState', () => {
  const frameStates = ref<FrameStateMap>({})

  const initializeProjectState = (project: Project) => {
    project.frames.forEach((frame) => {
      initializeFrameState(frame)
    })
  }

  const initializeFrameState = (frame: Frame) => {
    if (!frameStates.value[frame.id]) {
      const editorImage = frame.image
      const editorVideo = frame.video
      const openAIPrompt = frame.frameOptions?.find((fo) => fo.image === editorImage)?.payload
      const videoPayload = frame.videoOptions?.find((vo) => vo.url === editorVideo)?.payload

      if (openAIPrompt && !openAIPrompt.scene) {
        openAIPrompt!.scene = (frame as Frame & { scene: string }).scene
      }

      frameStates.value[frame.id] = {
        generationProgress: 'DONE',
        videoInProgress: false,
        imageInProgress: false,
        inpaintInProgress: false,
        styleTransferInProgress: false,
        loadingIndicators: 0,
        editorImage: editorImage ?? null,
        editorVideo: editorVideo ?? null,
        openAIPrompt: openAIPrompt ? reactive(openAIPrompt) : null,
        videoPayload: videoPayload ? reactive(videoPayload) : null
      }
    }
  }

  const updateFrameState = (frame: Frame, updates: Partial<FrameState>) => {
    console.debug('updating frame state', frame.id, updates)
    initializeFrameState(frame)
    frameStates.value[frame.id] = {
      ...frameStates.value[frame.id],
      ...updates
    }
  }

  const getFrameState = (frame: Frame): ComputedRef<FrameState> => computed(() => {
    if (!frameStates.value[frame.id]) {
      initializeFrameState(frame)
    }
    return frameStates.value[frame.id]
  })

  return {
    frameStates,
    initializeProjectState,
    initializeFrameState,
    updateFrameState,
    getFrameState
  }
})
