/**
 * main.js
 *
 * Bootstraps Vuetify and other plugins then mounts the App`
 */

// Components
import App from '@/App.vue'
// Composables
import { createApp } from 'vue'
// Plugins
import { registerPlugins } from '@/plugins'
import VueKonva from 'vue-konva'
import { mixpanelInit } from '@/services/mixpanel'

import * as Sentry from '@sentry/vue'

// Styles
import '@/styles/settings.scss'
import '@/styles/tailwind.css'

const app = createApp(App)

if (import.meta.env.MODE === 'production') {
  Sentry.init({
    app,
    dsn: 'https://9847d5c585977b854a8fb8449831d8a1@o4507958393634816.ingest.us.sentry.io/4506792992243712',
    integrations: [
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false
      })
    ],
    replaysSessionSampleRate: 0.0,
    replaysOnErrorSampleRate: 1.0
  })
}

app.use(VueKonva, { prefix: 'Konva' })

registerPlugins(app)

mixpanelInit()

// Init Fullstory

app.mount('#app')
