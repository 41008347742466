<template>
  <v-layout>
    <v-snackbar
      v-model="showSnackbar"
      color="error"
    >
      {{ snackbarText }}
    </v-snackbar>
  </v-layout>
  <div class="pricing-option">
    <template
      v-for="(option, index) in calculatedOptions"
      :key="index"
    >
      <pricing-option
        :option="option"
        :loading="loadingIndex === index"
        :yearly-billing-period="yearlyBillingPeriod"
        @select="(title: PaidPlan) => option.buttonAction(index, title)"
      />
    </template>
  </div>
</template>

<script setup lang="ts">
import tracking from '@/services/tracking'
import { onMounted, ref, computed, PropType } from 'vue'
import { useAppStore } from '@/store/app'
import { getAxios } from '@/services/axiosHelper'
import { useUserStore } from '@/store/user'

import PricingOption from '@/components/PricingOption.vue'
import { PaywallPrice, PaidPlan } from '@/types'
const appStore = useAppStore()
const userStore = useUserStore()

const props = defineProps({
  userEmail: {
    type: String,
    required: false
  },
  tier: {
    type: String,
    required: false
  },
  yearlyBillingPeriod: {
    type: Boolean,
    required: true
  },
  trialDisabled: {
    type: Boolean,
    required: false
  },
  currentBillingPeriod: {
    type: String as PropType<'month' | 'year'>,
    required: false
  }
})

const loadingIndex = ref<number>(-1)

const showSnackbar = ref<boolean>(false)
const snackbarText = ref<string>('')

const nonpayingTiers = ['Free', 'Demo', 'Unpaid', 'AppSumo Tier 1', 'AppSumo Tier 2', 'AppSumo Tier 3']

const calculatedOptions = computed(() => {
  return options
    .filter((option) => {
      if (props.yearlyBillingPeriod && props.currentBillingPeriod === 'month' && option.title === props.tier) {
        return true
      }
      return [...nonpayingTiers, ...option.visibleForTiers].includes(props.tier!)
    })
    .filter((option) => {
      return props.tier! !== 'Unpaid' || shownToNewUsers.has(option.title)
    })
    .map((option) => {
      if (props.yearlyBillingPeriod) {
        return {
          ...option,
          ...option.yearly
        }
      } else {
        return option
      }
    })
    .map((option) => {
      if (props.trialDisabled) {
        return {
          ...option,
          buttonText: 'Upgrade'
        }
      } else {
        return option
      }
    })
})

const shownToNewUsers = new Set([
  'Essential',
  'Pro',
  'Unlimited',
  'Enterprise'
])

const options: (PaywallPrice & { visibleForTiers: string[] })[] = [
  {
    visibleForTiers: [],
    title: 'Essential',
    description: 'For individuals looking to explore Katalist\'s storytelling platform capabilities.',
    price: '$39.00',
    pricePeriod: '/ month',
    buttonText: 'Try it free for 7 days',
    borderWidth: '2px',
    buttonBorderColor: '#000',
    buttonBackground: '#FFF',
    buttonTextColor: '#000',
    buttonAction: startPaidPlan,
    cancelText: 'Cancel Anytime',
    features: [
      '100 Video Credits',
      '20 Project Limit',
      '20 Panels per Project'
    ],
    fontFamily: 'Mulish-Bold',
    label: '',
    discountText: '50% off for 3 months',
    discountPrice: '$19.00',
    discountBackground: '#36c5f0',
    annualPrice: '',
    annualPriceText: '',
    generationCredits: '200 image credits / month',
    yearly: {
      price: '$29.00',
      annualPrice: '$348',
      annualPriceText: 'Billed Annually'
    },
    newFeatures: []
  },
  {
    visibleForTiers: ['Essential'],
    title: 'Pro',
    description: 'For professionals who need advanced features and more flexibility.',
    price: '$39.00',
    pricePeriod: '/ month',
    buttonText: 'Try it free for 7 days',
    borderWidth: '2px',
    buttonBorderColor: '#000',
    buttonBackground: '#000',
    buttonTextColor: '#FFF',
    buttonAction: startPaidPlan,
    cancelText: 'Cancel Anytime',
    features: [
      '150 Video Credits',
      '50 projects',
      '5 Custom Characters',
      '100+ panels per project'
    ],
    newFeatures: [
      'ZIP export',
      'PPT, Video Export'
    ],
    fontFamily: 'Mulish-Bold',
    label: 'Popular',
    discountBackground: '#FFC658',
    generationCredits: '700 image credits / month',
    yearly: {
      price: '$31.00',
      annualPrice: '$374',
      annualPriceText: 'Billed Annually'
    }
  },
  {
    visibleForTiers: ['Essential', 'Pro'],
    title: 'Unlimited',
    description: 'For power users and small teams needing unlimited resources.',
    price: '$99.00',
    pricePeriod: '/ month',
    buttonText: 'Try it free for 7 days',
    borderWidth: '2px',
    buttonBorderColor: '#000',
    buttonBackground: '#FFF',
    buttonTextColor: '#000',
    buttonAction: startPaidPlan,
    cancelText: 'Cancel Anytime',
    features: [
      'Unlimited Video Credits',
      'Unlimited projects',
      '10 Custom Characters',
      '200+ panels per project',
      'ZIP export',
      'PPT, Video Export'
    ],
    newFeatures: [
      '2 users'
    ],
    discountBackground: '#FFC658',
    fontFamily: 'Mulish-Bold',
    generationCredits: 'Unlimited image generation',
    yearly: {
      price: '$79.00',
      annualPrice: '$948',
      annualPriceText: 'Billed Annually'
    }
  },
  {
    visibleForTiers: ['Essential', 'Individual', 'Pro'],
    title: 'Business',
    description: 'For teams agencies, studios, and brands that want to work together in real time.',
    price: '$499.00',
    pricePeriod: '/ month for 5 users',
    buttonText: 'Try it free for 7 days',
    buttonBorderColor: '#000',
    buttonBackground: '#FFF',
    buttonTextColor: '#000',
    buttonAction: startPaidPlan,
    cancelText: 'Cancel Anytime',
    borderWidth: '2px',
    features: [
      '5 users',
      'Unlimited video credits',
      'Unlimited projects',
      '25 custom characters',
      '200+ panels per project',
      'ZIP export',
      'PPT, Video export'
    ],
    newFeatures: [
      'Real-time collaboration'
    ],
    discountBackground: '#111',
    fontFamily: 'Mulish-Bold',
    generationCredits: 'Unlimited image generation',
    yearly: {
      annualPrice: '$4788',
      annualPriceText: 'Billed Annually',
      price: '$399.00'
    }
  },
  {
    visibleForTiers: ['Essential', 'Individual', 'Business', 'Pro'],
    title: 'Enterprise',
    description: 'For large organisations that work at scale.',
    price: 'Contact Us',
    buttonText: 'Book Demo',
    buttonBorderColor: '#000',
    buttonBackground: '#FFF',
    buttonTextColor: '#000',
    buttonAction: getInTouch,
    borderWidth: '2px',
    features: [
      'Unlimited Users',
      'Unlimited video credits',
      'Unlimited projects',
      'Unlimited custom characters',
      '200+ panels per project',
      'ZIP export',
      'PPT, Video export',
      'Real-time collaboration'
    ],
    newFeatures: [
      'Enterprise Integrations',
      '24/7 Dedicated Support'
    ],
    generationCredits: 'Scaleable for large orgs',
    discountBackground: '#111',
    fontFamily: 'Mulish-Bold',
    yearly: {}
  }
]

onMounted(async () => {
  if (!appStore.projects) {
    await appStore.getProjects()
  }
})

async function startPaidPlan (index: number, tier: PaywallPrice['title']) {
  if (loadingIndex.value !== -1) {
    return
  }

  const trial = userStore.org!.tier !== 'Free' && userStore.org?.subscription_status === 'registered'

  let mappedTier
  if (tier === 'Unlimited') {
    mappedTier = 'Individual'
  } else {
    mappedTier = tier
  }

  loadingIndex.value = index
  tracking.track('Plan Selected', { 'Plan type': tier, trial })

  const axios = await getAxios()

  const checkoutUrl = '/api/checkout'

  try {
    const response = await axios.get(checkoutUrl, {
      params: {
        customerEmail: props.userEmail!,
        tier: mappedTier,
        trial,
        seats: 5,
        billingPeriod: props.yearlyBillingPeriod ? 'year' : 'month',
        applyIndividualDiscount: false
      }
    })
    window.location.assign(response.data.redirect_url)
  } catch (e: any) {
    loadingIndex.value = -1
    showSnackbar.value = true
    if (e.response?.data?.detail) {
      snackbarText.value = e.response.data.detail
    } else {
      snackbarText.value = 'An error occurred. Please try again later.'
    }
  }
}

function getInTouch () {
  tracking.track('Plan Selected', { 'Plan type': 'Studio', CTA: 'Get in touch' })
  window.location.assign('mailto:sales@katalist.ai&subject=Studio')
}
</script>

<style scoped lang="scss">
@import "@/styles/settings";

$light-pink: #FFF4FA;
$dark-pink: #F2CEE2;

.pricing-option {
  justify-content: center;
  align-items: end;
  gap: 56px;
  display: flex;
  flex-flow: column nowrap;
  padding-top: 30px;
  padding-bottom: 30px;

  @include xl {
    flex-direction: row;
  }
}

</style>
