import { analytics } from '@/services/segment'
import mixpanel from 'mixpanel-browser'
import { User } from '@supabase/supabase-js'
import { Organization } from '@/types'

const INTERNAL_USER_KEY = 'is_internal_user'
const INTERNAL_DOMAIN = '@katalist.ai'

function isInternalUser (email: string): boolean {
  return email.endsWith(INTERNAL_DOMAIN)
}

function setInternalUserFlag (isInternal: boolean) {
  localStorage.setItem(INTERNAL_USER_KEY, JSON.stringify(isInternal))
}

function getInternalUserFlag (): boolean {
  const flag = localStorage.getItem(INTERNAL_USER_KEY)
  return flag ? JSON.parse(flag) : false
}

export default {
  track: (event: string, properties: Record<string, string | number | object | null | boolean> = {}) => {
    if (process.env.NODE_ENV === 'development' || getInternalUserFlag()) {
      console.log(`[TRACK] ${event}`, properties)
    }

    analytics.track(event, properties)
    mixpanel.track(event, properties)
    // @ts-expect-error window has no intercom property
    window.Intercom('trackEvent', event, properties)
  },
  page: (name: string, properties: Record<string, string | number | object | null | boolean> = {}) => {
    analytics.page(name, properties)
    mixpanel.track_pageview({ page: name })
    // @ts-expect-error window has no intercom property
    window.Intercom('trackEvent', 'page view', { page: name })

    if (process.env.NODE_ENV === 'development' || getInternalUserFlag()) {
      console.log(`[PAGE] ${name}`, properties)
    }
  },
  identify: (user: User, org: Organization) => {
    analytics.identify(user.id, {
      email: user.email!,
      'Org Owner Email': org.ownerEmail!,
      Tier: org.tier!,
      SubscriptionStatus: org.subscription_status,
      userId: user.id
    })

    const isInternal = isInternalUser(user.email!)
    setInternalUserFlag(isInternal)

    if (process.env.NODE_ENV === 'development' || isInternal) {
      console.log(`[IDENTIFY] ${user.email} from ${org.ownerEmail}`)
    }
  },
  traits: (id: string, traits: Record<string, string | number> = {}) => {
    analytics.identify(id, traits)
    mixpanel.people.set(traits)

    if (process.env.NODE_ENV === 'development' || getInternalUserFlag()) {
      console.log(`[TRAITS] ${id}`, traits)
    }
  }
}
