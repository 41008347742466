import { watchEffect, type Ref, nextTick } from 'vue'

type DialogRef =
  | Ref<boolean | undefined>
  | {
      value: boolean | undefined;
    };

// fix to prevent AppBar from going off the top of the page when opening a v-dialog.
// Issue: https://github.com/vuetifyjs/vuetify/issues/16770
// Cause: <html> gets an additional style property of -v-body-scroll-y with a -ve pixel value when modal is opened.

export function useDialogScrollFix (refs: DialogRef | DialogRef[]) {
  watchEffect(async () => {
    const refsArray = Array.isArray(refs) ? refs : [refs]

    const shouldHideOverflow = refsArray.some((ref) => ref.value === true)

    // needed to correctly handle a dialog that opens another dialog.
    if (shouldHideOverflow) await nextTick()

    document.documentElement.style.overflow = shouldHideOverflow
      ? 'hidden'
      : ''
  })
}
