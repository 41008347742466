import mixpanel from 'mixpanel-browser'

export const mixpanelInit = () => {
  let token = '587b6e34c7e73efd9b71a5b7d2c4a8a4'
  if (process.env.NODE_ENV === 'development') {
    token = 'e63fd258cb55d2216eb43094b33fb6da'
  }
  mixpanel.init(token, { debug: false, track_pageview: true, persistence: 'cookie', cross_subdomain_cookie: true, api_host: 'https://tracking-proxy-pmevbs4dva-uc.a.run.app' })
  console.debug('Mixpanel initialized')
}

export const installMixpanel = (userId: string, userEmail: string, orgTier: string, orgEmail: string) => {
  // Set this to a unique identifier for the user performing the event.
  mixpanel.identify(userId)

  // Add user information to user profile
  mixpanel.people.set({
    $email: userEmail,
    'Org Owner Email': orgEmail,
    Tier: orgTier
  })
}
